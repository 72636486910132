import React from 'react';
import PropTypes from 'prop-types';

export const PropsContext = React.createContext();
export const PropsProvider = ({ widgetProps, children }) => (
    <PropsContext.Provider value={widgetProps}>{children}</PropsContext.Provider>
);

PropsProvider.propTypes = {
    widgetProps: PropTypes.any,
    children: PropTypes.any,
};